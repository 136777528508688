.form-multi-select {
  // scss-docs-start form-multi-select-css-vars
  --#{$prefix}form-multi-select-zindex: #{$form-multi-select-zindex};
  --#{$prefix}form-multi-select-font-family: #{$form-multi-select-font-family};
  --#{$prefix}form-multi-select-font-size: #{$form-multi-select-font-size};
  --#{$prefix}form-multi-select-font-weight: #{$form-multi-select-font-weight};
  --#{$prefix}form-multi-select-line-height: #{$form-multi-select-line-height};
  --#{$prefix}form-multi-select-color: #{$form-multi-select-color};
  --#{$prefix}form-multi-select-bg: #{$form-multi-select-bg};
  --#{$prefix}form-multi-select-box-shadow: #{$form-multi-select-box-shadow};
  --#{$prefix}form-multi-select-border-width: #{$form-multi-select-border-width};
  --#{$prefix}form-multi-select-border-color: #{$form-multi-select-border-color};
  --#{$prefix}form-multi-select-border-radius: #{$form-multi-select-border-radius};
  --#{$prefix}form-multi-select-disabled-color: #{$form-multi-select-disabled-color};
  --#{$prefix}form-multi-select-disabled-bg: #{$form-multi-select-disabled-bg};
  --#{$prefix}form-multi-select-disabled-border-color: #{$form-multi-select-disabled-border-color};
  --#{$prefix}form-multi-select-focus-color: #{$form-multi-select-focus-color};
  --#{$prefix}form-multi-select-focus-bg: #{$form-multi-select-focus-bg};
  --#{$prefix}form-multi-select-focus-border-color: #{$form-multi-select-focus-border-color};
  --#{$prefix}form-multi-select-focus-box-shadow: #{$form-multi-select-focus-box-shadow};
  --#{$prefix}form-multi-select-placeholder-color: #{$form-multi-select-placeholder-color};
  --#{$prefix}form-multi-select-selection-padding-y: #{$form-multi-select-selection-padding-y};
  --#{$prefix}form-multi-select-selection-padding-x: #{$form-multi-select-selection-padding-x};
  --#{$prefix}form-multi-select-cleaner-width: #{$form-multi-select-cleaner-width};
  --#{$prefix}form-multi-select-cleaner-height: #{$form-multi-select-cleaner-height};
  --#{$prefix}form-multi-select-cleaner-padding-y: #{$form-multi-select-cleaner-padding-y};
  --#{$prefix}form-multi-select-cleaner-padding-x: #{$form-multi-select-cleaner-padding-x};
  --#{$prefix}form-multi-select-cleaner-icon: #{escape-svg($form-multi-select-cleaner-icon)};
  --#{$prefix}form-multi-select-cleaner-icon-color: #{$form-multi-select-cleaner-icon-color};
  --#{$prefix}form-multi-select-cleaner-icon-hover-color: #{$form-multi-select-cleaner-icon-hover-color};
  --#{$prefix}form-multi-select-cleaner-icon-size: #{$form-multi-select-cleaner-icon-size};
  --#{$prefix}form-multi-select-indicator-width: #{$form-multi-select-indicator-width};
  --#{$prefix}form-multi-select-indicator-height: #{$form-multi-select-indicator-height};
  --#{$prefix}form-multi-select-indicator-padding-y: #{$form-multi-select-indicator-padding-y};
  --#{$prefix}form-multi-select-indicator-padding-x: #{$form-multi-select-indicator-padding-x};
  --#{$prefix}form-multi-select-indicator-icon: #{escape-svg($form-multi-select-indicator-icon)};
  --#{$prefix}form-multi-select-indicator-icon-color: #{$form-multi-select-indicator-icon-color};
  --#{$prefix}form-multi-select-indicator-icon-hover-color: #{$form-multi-select-indicator-icon-hover-color};
  --#{$prefix}form-multi-select-indicator-icon-size: #{$form-multi-select-indicator-icon-size};
  --#{$prefix}form-multi-select-select-all-padding-y: #{$form-multi-select-select-all-padding-y};
  --#{$prefix}form-multi-select-select-all-padding-x: #{$form-multi-select-select-all-padding-x};
  --#{$prefix}form-multi-select-select-all-color: #{$form-multi-select-select-all-color};
  --#{$prefix}form-multi-select-select-all-bg: #{$form-multi-select-select-all-bg};
  --#{$prefix}form-multi-select-select-all-border-width: #{$form-multi-select-select-all-border-width};
  --#{$prefix}form-multi-select-select-all-border-color: #{$form-multi-select-select-all-border-color};
  --#{$prefix}form-multi-select-select-all-hover-color: #{$form-multi-select-select-all-hover-color};
  --#{$prefix}form-multi-select-select-all-hover-bg: #{$form-multi-select-select-all-hover-bg};
  --#{$prefix}form-multi-select-dropdown-min-width: #{$form-multi-select-dropdown-min-width};
  --#{$prefix}form-multi-select-dropdown-bg: #{$form-multi-select-dropdown-bg};
  --#{$prefix}form-multi-select-dropdown-border-width: #{$form-multi-select-dropdown-border-width};
  --#{$prefix}form-multi-select-dropdown-border-color: #{$form-multi-select-dropdown-border-color};
  --#{$prefix}form-multi-select-dropdown-border-radius: #{$form-multi-select-dropdown-border-radius};
  --#{$prefix}form-multi-select-dropdown-box-shadow: #{$form-multi-select-dropdown-box-shadow};
  --#{$prefix}form-multi-select-options-padding-y: #{$form-multi-select-options-padding-y};
  --#{$prefix}form-multi-select-options-padding-x: #{$form-multi-select-options-padding-x};
  --#{$prefix}form-multi-select-options-font-size: #{$form-multi-select-options-font-size};
  --#{$prefix}form-multi-select-options-font-weight: #{$form-multi-select-options-font-weight};
  --#{$prefix}form-multi-select-options-color: #{$form-multi-select-options-color};
  --#{$prefix}form-multi-select-optgroup-label-padding-y: #{$form-multi-select-optgroup-label-padding-y};
  --#{$prefix}form-multi-select-optgroup-label-padding-x: #{$form-multi-select-optgroup-label-padding-x};
  --#{$prefix}form-multi-select-optgroup-label-font-size: #{$form-multi-select-optgroup-label-font-size};
  --#{$prefix}form-multi-select-optgroup-label-font-weight: #{$form-multi-select-optgroup-label-font-weight};
  --#{$prefix}form-multi-select-optgroup-label-color: #{$form-multi-select-optgroup-label-color};
  --#{$prefix}form-multi-select-optgroup-label-text-transform: #{$form-multi-select-optgroup-label-text-transform};
  --#{$prefix}form-multi-select-option-padding-y: #{$form-multi-select-option-padding-y};
  --#{$prefix}form-multi-select-option-padding-x: #{$form-multi-select-option-padding-x};
  --#{$prefix}form-multi-select-option-margin-y: #{$form-multi-select-option-margin-y};
  --#{$prefix}form-multi-select-option-margin-x: #{$form-multi-select-option-margin-x};
  --#{$prefix}form-multi-select-option-border-width: #{$form-multi-select-option-border-width};
  --#{$prefix}form-multi-select-option-border-color: #{$form-multi-select-option-border-color};
  --#{$prefix}form-multi-select-option-border-radius: #{$form-multi-select-option-border-radius};
  --#{$prefix}form-multi-select-option-box-shadow: #{$form-multi-select-option-box-shadow};
  --#{$prefix}form-multi-select-option-hover-color: #{$form-multi-select-option-hover-color};
  --#{$prefix}form-multi-select-option-hover-bg: #{$form-multi-select-option-hover-bg};
  --#{$prefix}form-multi-select-option-focus-box-shadow: #{$form-multi-select-option-focus-box-shadow};
  --#{$prefix}form-multi-select-option-disabled-color: #{$form-multi-select-option-disabled-color};
  --#{$prefix}form-multi-select-option-indicator-width: #{$form-multi-select-option-indicator-width};
  --#{$prefix}form-multi-select-option-indicator-bg: #{$form-multi-select-option-indicator-bg};
  --#{$prefix}form-multi-select-option-indicator-border: #{$form-multi-select-option-indicator-border};
  --#{$prefix}form-multi-select-option-indicator-border-radius: #{$form-multi-select-option-indicator-border-radius};
  --#{$prefix}form-multi-select-option-selected-bg: #{$form-multi-select-option-selected-bg};
  --#{$prefix}form-multi-select-option-selected-indicator-bg: #{$form-multi-select-option-selected-indicator-bg};
  --#{$prefix}form-multi-select-option-selected-indicator-bg-image: #{escape-svg($form-multi-select-option-selected-indicator-bg-image)};
  --#{$prefix}form-multi-select-option-selected-indicator-border-color: #{$form-multi-select-option-selected-indicator-border-color};
  --#{$prefix}form-multi-select-tag-padding-y: #{$form-multi-select-tag-padding-y};
  --#{$prefix}form-multi-select-tag-padding-x: #{$form-multi-select-tag-padding-x};
  --#{$prefix}form-multi-select-tag-bg: #{$form-multi-select-tag-bg};
  --#{$prefix}form-multi-select-tag-border-width: #{$form-multi-select-tag-border-width};
  --#{$prefix}form-multi-select-tag-border-color: #{$form-multi-select-tag-border-color};
  --#{$prefix}form-multi-select-tag-border-radius: #{$form-multi-select-tag-border-radius};
  --#{$prefix}form-multi-select-tag-delete-width: #{$form-multi-select-tag-delete-width};
  --#{$prefix}form-multi-select-tag-delete-height: #{$form-multi-select-tag-delete-height};
  --#{$prefix}form-multi-select-tag-delete-icon: #{escape-svg($form-multi-select-tag-delete-icon)};
  --#{$prefix}form-multi-select-tag-delete-icon-color: #{$form-multi-select-tag-delete-icon-color};
  --#{$prefix}form-multi-select-tag-delete-icon-hover-color: #{$form-multi-select-tag-delete-icon-hover-color};
  --#{$prefix}form-multi-select-tag-delete-icon-size: #{$form-multi-select-tag-delete-icon-size};
  --#{$prefix}form-multi-select-selection-tags-gap: #{$form-multi-select-selection-tags-gap};
  --#{$prefix}form-multi-select-selection-tags-padding-y: #{$form-multi-select-selection-tags-padding-y};
  --#{$prefix}form-multi-select-selection-tags-padding-x: #{$form-multi-select-selection-tags-padding-x};
  // scss-docs-end form-multi-select-css-vars

  position: relative;

  .was-validated .form-multi-select:invalid + &,
  &.is-invalid {
    --#{$prefix}form-multi-select-border-color: #{$form-multi-select-invalid-border-color};
  }

  .was-validated .form-multi-select:valid + &,
  &.is-valid {
    --#{$prefix}form-multi-select-border-color: #{$form-multi-select-valid-border-color};
  }
}

// stylelint-disable-next-line selector-no-qualifying-type
select.form-multi-select {
  display: none;
}

.form-multi-select-input-group {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
  font-family: var(--#{$prefix}form-multi-select-font-family);
  @include font-size(var(--#{$prefix}form-multi-select-font-size));
  font-weight: var(--#{$prefix}form-multi-select-font-weight);
  line-height: var(--#{$prefix}form-multi-select-line-height);
  color: var(--#{$prefix}form-multi-select-color);
  background-color: var(--#{$prefix}form-multi-select-bg);
  background-clip: padding-box;
  border: var(--#{$prefix}form-multi-select-border-width) solid var(--#{$prefix}form-multi-select-border-color);
  @include border-radius(var(--#{$prefix}form-multi-select-border-radius));

  .form-multi-select & {
    @include transition($input-transition);
  }

  .form-multi-select.disabled & {
    color: var(--#{$prefix}form-multi-select-disabled-color);
    background-color: var(--#{$prefix}form-multi-select-disabled-bg);
    border-color: $input-disabled-border-color;
  }

  .form-multi-select.show & {
    color: var(--#{$prefix}form-multi-select-focus-color);
    background-color: var(--#{$prefix}form-multi-select-focus-bg);
    border-color: var(--#{$prefix}form-multi-select-focus-border-color);
    outline: 0;
    @if $enable-shadows {
      box-shadow: var(--#{$prefix}form-multi-select-box-shadow), var(--#{$prefix}form-multi-select-focus-box-shadow);
    } @else {
      box-shadow: var(--#{$prefix}form-multi-select-focus-box-shadow);
    }
  }
}

.form-multi-select-selection {
  position: relative;
  display: flex;
  flex: 1 1 auto;
  flex-wrap: wrap;
  width: 1%;
  min-width: 0;
  padding: var(--#{$prefix}form-multi-select-selection-padding-y) var(--#{$prefix}form-multi-select-selection-padding-x);
}

.form-multi-select-selection-tags {
  gap: var(--#{$prefix}form-multi-select-selection-tags-gap);
  align-content: center;
  padding: var(--#{$prefix}form-multi-select-selection-tags-padding-y) var(--#{$prefix}form-multi-select-selection-tags-padding-x);
}

.form-multi-select-search {
  display: none;
  flex: 1 1 auto;
  max-width: 100%;
  padding: 0;
  background: transparent;
  border: 0;

  &:focus {
    outline: 0;
  }

  &::placeholder {
    color: var(--#{$prefix}form-multi-select-placeholder-color);
    opacity: 1;
  }

  .form-multi-select.show &,
  &:placeholder-shown {
    display: flex;
  }

  .form-multi-select-selection-tags & {
    padding-inline-start: calc(var(--#{$prefix}form-multi-select-selection-padding-x) - .25rem); // stylelint-disable-line function-disallowed-list
  }
}

.form-multi-select-placeholder {
  color: var(--#{$prefix}form-multi-select-placeholder-color);

  .form-multi-select-selection-tags & {
    padding: calc(var(--#{$prefix}form-multi-select-selection-padding-y) - .25rem) calc(var(--#{$prefix}form-multi-select-selection-padding-x) - .25rem); // stylelint-disable-line function-disallowed-list
  }
}

.form-multi-select-buttons {
  display: flex;
  align-items: center;
  min-height: calc(2 * var(--#{$prefix}form-multi-select-selection-padding-y) + calc(var(--#{$prefix}form-multi-select-font-size) * var(--#{$prefix}form-multi-select-line-height)));  // stylelint-disable-line function-disallowed-list
  padding: 0 var(--#{$prefix}form-multi-select-selection-padding-y);
}

.form-multi-select-cleaner,
.form-multi-select-indicator {
  position: relative;
  z-index: 2;
  box-sizing: content-box;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center;
  border: 0;

  &:focus {
    z-index: 5;
    outline: 0;
    box-shadow: var(--#{$prefix}focus-ring-x, 0) var(--#{$prefix}focus-ring-y, 0) var(--#{$prefix}focus-ring-blur, 0) var(--#{$prefix}focus-ring-width) var(--#{$prefix}focus-ring-color);
    @include border-radius($border-radius);
  }

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: "";
  }
}

.form-multi-select-cleaner {
  width: var(--#{$prefix}form-multi-select-cleaner-width);
  height: var(--#{$prefix}form-multi-select-cleaner-height);
  padding: var(--#{$prefix}form-multi-select-cleaner-padding-y) var(--#{$prefix}form-multi-select-cleaner-padding-x);

  &::before {
    background-color: var(--#{$prefix}form-multi-select-cleaner-icon-color);
    mask: var(--#{$prefix}form-multi-select-cleaner-icon) center / var(--#{$prefix}form-multi-select-cleaner-icon-size) no-repeat;
  }

  &:focus::before,
  &:hover::before {
    background-color: var(--#{$prefix}form-multi-select-cleaner-icon-hover-color);
  }
}

.form-multi-select-indicator {
  width: var(--#{$prefix}form-multi-select-indicator-width);
  height: var(--#{$prefix}form-multi-select-indicator-height);
  padding: var(--#{$prefix}form-multi-select-indicator-padding-y) var(--#{$prefix}form-multi-select-indicator-padding-x);
  @include transition(transform .15s ease-in-out);

  &::before {
    background-color: var(--#{$prefix}form-multi-select-indicator-icon-color);
    mask: var(--#{$prefix}form-multi-select-indicator-icon) center / var(--#{$prefix}form-multi-select-indicator-icon-size) no-repeat;
  }

  &:focus::before,
  &:hover::before {
    background-color: var(--#{$prefix}form-multi-select-indicator-icon-hover-color);
  }

  .form-multi-select.show & {
    transform: rotate(180deg);
  }
}

.form-multi-select-tag {
  display: flex;
  align-items: center;
  padding: var(--#{$prefix}form-multi-select-tag-padding-y) var(--#{$prefix}form-multi-select-tag-padding-x);
  background-color: var(--#{$prefix}form-multi-select-tag-bg);
  border: var(--#{$prefix}form-multi-select-tag-border-width) solid var(--#{$prefix}form-multi-select-tag-border-color);
  @include border-radius(var(--#{$prefix}form-multi-select-tag-border-radius));
}

.form-multi-select-tag-delete {
  position: relative;
  z-index: 2;
  box-sizing: content-box;
  width: var(--#{$prefix}form-multi-select-tag-delete-width);
  height: var(--#{$prefix}form-multi-select-tag-delete-height);
  padding: var(--#{$prefix}form-multi-select-tag-delete-padding-y) var(--#{$prefix}form-multi-select-tag-delete-padding-x);
  margin-inline-start: $spacer * .5;
  background-color: transparent;
  border: 0;

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: "";
    background-color: var(--#{$prefix}form-multi-select-tag-delete-icon-color);
    mask: var(--#{$prefix}form-multi-select-tag-delete-icon) center / var(--#{$prefix}form-multi-select-tag-delete-icon-size) no-repeat;
  }

  &:focus::before,
  &:hover::before {
    background-color: var(--#{$prefix}form-multi-select-tag-delete-icon-hover-color);
  }
}

.form-multi-select-dropdown {
  position: absolute;
  z-index: var(--#{$prefix}form-multi-select-zindex);
  display: none; // none by default, but block on "open" of the menu
  min-width: var(--#{$prefix}form-multi-select-dropdown-min-width);
  background-color: var(--#{$prefix}form-multi-select-dropdown-bg);
  background-clip: padding-box;
  border: var(--#{$prefix}form-multi-select-dropdown-border-width) solid var(--#{$prefix}form-multi-select-dropdown-border-color);
  @include border-radius(var(--#{$prefix}form-multi-select-dropdown-border-radius));
  @include box-shadow(var(--#{$prefix}form-multi-select-dropdown-box-shadow));
  @include elevation(4);

  .form-multi-select.show & {
    display: block;
  }
}

.form-multi-select-all {
  display: block;
  width: 100%;
  padding: var(--#{$prefix}form-multi-select-select-all-padding-y) var(--#{$prefix}form-multi-select-select-all-padding-x);
  color: var(--#{$prefix}form-multi-select-select-all-color);
  text-align: start;
  background-color: var(--#{$prefix}form-multi-select-select-all-bg);
  border: 0;
  border-bottom: var(--#{$prefix}form-multi-select-select-all-border-width) solid var(--#{$prefix}form-multi-select-select-all-border-color);

  &:hover {
    color: var(--#{$prefix}form-multi-select-select-all-hover-color);
    background-color: var(--#{$prefix}form-multi-select-select-all-hover-bg);
  }
}

.form-multi-select-options {
  padding: var(--#{$prefix}form-multi-select-options-padding-y) var(--#{$prefix}form-multi-select-options-padding-x);
  font-size: var(--#{$prefix}form-multi-select-options-font-size);
  font-weight: var(--#{$prefix}form-multi-select-options-font-weight);
  color: var(--#{$prefix}form-multi-select-options-color);
}

.form-multi-select-option {
  position: relative;
  z-index: 2;
  padding: var(--#{$prefix}form-multi-select-option-padding-y) var(--#{$prefix}form-multi-select-option-padding-x);
  margin: var(--#{$prefix}form-multi-select-option-margin-y) var(--#{$prefix}form-multi-select-option-margin-x);
  cursor: pointer;
  border: var(--#{$prefix}form-multi-select-option-border-width) solid var(--#{$prefix}form-multi-select-option-border-color);
  @include border-radius(var(--#{$prefix}form-multi-select-option-border-radius), 0);

  &:hover,
  &:focus {
    color: var(--#{$prefix}form-multi-select-option-hover-color);
    text-decoration: none;
    background-color: var(--#{$prefix}form-multi-select-option-hover-bg);
  }

  &:focus {
    z-index: 5;
    border-color: var(--#{$prefix}input-focus-border-color, $input-focus-border-color);
    outline: 0;
    @if $enable-shadows {
      box-shadow: var(--#{$prefix}form-multi-select-box-shadow), var(--#{$prefix}form-multi-select-focus-box-shadow);
    } @else {
      box-shadow: var(--#{$prefix}form-multi-select-focus-box-shadow);
    }
  }

  &.disabled {
    color: var(--#{$prefix}form-multi-select-option-disabled-color);
    pointer-events: none;
    background-color: transparent;
  }

  &.form-multi-select-option-with-checkbox {
    padding: $form-multi-select-option-padding-y $form-multi-select-option-padding-x;
    @include ltr-rtl("padding-left", calc(var(--#{$prefix}form-multi-select-option-padding-x) + var(--#{$prefix}form-multi-select-option-indicator-width)));
    &::before {
      position: absolute;
      top: .7rem;
      @include ltr-rtl("left",  calc(var(--#{$prefix}form-multi-select-option-padding-x) * .5));
      display: block;
      width: var(--#{$prefix}form-multi-select-option-indicator-width);
      height: var(--#{$prefix}form-multi-select-option-indicator-width);
      pointer-events: none;
      content: "";
      background-color: var(--#{$prefix}form-multi-select-option-indicator-bg);
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      border: var(--#{$prefix}form-multi-select-option-indicator-border);
      @include border-radius(var(--#{$prefix}form-multi-select-option-indicator-border-radius));
    }
  }


  &.form-multi-selected {
    background-color: var(--#{$prefix}form-multi-select-option-selected-bg);

    &::before {
      background-color: var(--#{$prefix}form-multi-select-option-selected-indicator-bg);
      background-image: var(--#{$prefix}form-multi-select-option-selected-indicator-bg-image);
      border-color: var(--#{$prefix}form-multi-select-option-selected-indicator-border-color);
    }
  }
}

.form-multi-select-optgroup-label {
  padding: var(--#{$prefix}form-multi-select-options-padding-y) var(--#{$prefix}form-multi-select-options-padding-x);
  font-size: var(--#{$prefix}form-multi-select-optgroup-label-font-size);
  font-weight: var(--#{$prefix}form-multi-select-optgroup-label-font-weight);
  color: var(--#{$prefix}form-multi-select-optgroup-label-color);
  text-transform: var(--#{$prefix}form-multi-select-optgroup-label-text-transform);
}

.form-multi-select-options-empty {
  padding: var(--#{$prefix}form-multi-select-option-padding-y) var(--#{$prefix}form-multi-select-option-padding-x);
}


.form-multi-select-sm {
  --#{$prefix}form-multi-select-selection-padding-y: #{$form-multi-select-selection-padding-y-sm};
  --#{$prefix}form-multi-select-selection-padding-x: #{$form-multi-select-selection-padding-x-sm};
  --#{$prefix}form-multi-select-font-size: #{$form-multi-select-font-size-sm};
  --#{$prefix}form-multi-select-border-radius: #{$form-multi-select-border-radius-sm};
  --#{$prefix}form-multi-select-tag-padding-y: #{$form-multi-select-tag-padding-y-sm};
  --#{$prefix}form-multi-select-tag-padding-x: #{$form-multi-select-tag-padding-x-sm};
  --#{$prefix}form-multi-select-tag-border-radius: #{$form-multi-select-tag-border-radius-sm};
  --#{$prefix}form-multi-select-selection-tags-gap: #{$form-multi-select-selection-tags-gap-sm};
  --#{$prefix}form-multi-select-selection-tags-padding-y: #{$form-multi-select-selection-tags-padding-y-sm};
  --#{$prefix}form-multi-select-selection-tags-padding-x: #{$form-multi-select-selection-tags-padding-x-sm};
}

.form-multi-select-lg {
  --#{$prefix}form-multi-select-selection-padding-y: #{$form-multi-select-selection-padding-y-lg};
  --#{$prefix}form-multi-select-selection-padding-x: #{$form-multi-select-selection-padding-x-lg};
  --#{$prefix}form-multi-select-font-size: #{$form-multi-select-font-size-lg};
  --#{$prefix}form-multi-select-border-radius: #{$form-multi-select-border-radius-lg};
  --#{$prefix}form-multi-select-tag-padding-y: #{$form-multi-select-tag-padding-y-lg};
  --#{$prefix}form-multi-select-tag-padding-x: #{$form-multi-select-tag-padding-x-lg};
  --#{$prefix}form-multi-select-tag-border-radius: #{$form-multi-select-tag-border-radius-lg};
  --#{$prefix}form-multi-select-selection-tags-gap: #{$form-multi-select-selection-tags-gap-lg};
  --#{$prefix}form-multi-select-selection-tags-padding-y: #{$form-multi-select-selection-tags-padding-y-lg};
  --#{$prefix}form-multi-select-selection-tags-padding-x: #{$form-multi-select-selection-tags-padding-x-lg};
}
