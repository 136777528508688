// Sidebar navigation

.sidebar-nav {
  // scss-docs-start sidebar-nav-css-vars
  --#{$prefix}sidebar-nav-padding-x: #{$sidebar-nav-padding-x};
  --#{$prefix}sidebar-nav-padding-y: #{$sidebar-nav-padding-y};

  --#{$prefix}sidebar-nav-title-padding-x: #{$sidebar-nav-title-padding-x};
  --#{$prefix}sidebar-nav-title-padding-y: #{$sidebar-nav-title-padding-y};
  --#{$prefix}sidebar-nav-title-margin-top: #{$sidebar-nav-title-margin-top};
  --#{$prefix}sidebar-nav-title-color: #{$sidebar-nav-title-color};

  --#{$prefix}sidebar-nav-link-padding-x: #{$sidebar-nav-link-padding-x};
  --#{$prefix}sidebar-nav-link-padding-y: #{$sidebar-nav-link-padding-y};
  --#{$prefix}sidebar-nav-link-color: #{$sidebar-nav-link-color};
  --#{$prefix}sidebar-nav-link-bg: #{$sidebar-nav-link-bg};
  --#{$prefix}sidebar-nav-link-border-color: #{$sidebar-nav-link-border-color};
  --#{$prefix}sidebar-nav-link-border-radius: #{$sidebar-nav-link-border-radius};
  --#{$prefix}sidebar-nav-link-border-width: #{$sidebar-nav-link-border-width};

  --#{$prefix}sidebar-nav-link-active-color: #{$sidebar-nav-link-active-color};
  --#{$prefix}sidebar-nav-link-active-bg: #{$sidebar-nav-link-active-bg};
  --#{$prefix}sidebar-nav-link-disabled-color: #{$sidebar-nav-link-disabled-color};
  --#{$prefix}sidebar-nav-link-hover-color: #{$sidebar-nav-link-hover-color};
  --#{$prefix}sidebar-nav-link-hover-bg: #{$sidebar-nav-link-hover-bg};

  --#{$prefix}sidebar-nav-link-icon-margin: #{$sidebar-nav-link-icon-margin};
  --#{$prefix}sidebar-nav-link-icon-color: #{$sidebar-nav-link-icon-color};
  --#{$prefix}sidebar-nav-link-icon-width: #{$sidebar-nav-link-icon-width};
  --#{$prefix}sidebar-nav-link-icon-height: #{$sidebar-nav-link-icon-height};
  --#{$prefix}sidebar-nav-link-icon-font-size: #{$sidebar-nav-link-icon-font-size};
  --#{$prefix}sidebar-nav-link-active-icon-color: #{$sidebar-nav-link-active-icon-color};
  --#{$prefix}sidebar-nav-link-disabled-icon-color: #{$sidebar-nav-link-disabled-icon-color};
  --#{$prefix}sidebar-nav-link-hover-icon-color: #{$sidebar-nav-link-hover-icon-color};

  --#{$prefix}sidebar-nav-link-icon-bullet-size: #{$sidebar-nav-link-icon-bullet-size};
  --#{$prefix}sidebar-nav-link-icon-bullet-bg: #{$sidebar-nav-link-icon-bullet-bg};
  --#{$prefix}sidebar-nav-link-icon-bullet-border-width: #{$sidebar-nav-link-icon-bullet-border-width};
  --#{$prefix}sidebar-nav-link-icon-bullet-border-radius: #{$sidebar-nav-link-icon-bullet-border-radius};
  --#{$prefix}sidebar-nav-link-icon-bullet-border-color: #{$sidebar-nav-link-icon-bullet-border-color};
  --#{$prefix}sidebar-nav-link-active-icon-bullet-bg: #{$sidebar-nav-link-active-icon-bullet-bg};
  --#{$prefix}sidebar-nav-link-active-icon-bullet-border-color: #{$sidebar-nav-link-active-icon-bullet-border-color};
  --#{$prefix}sidebar-nav-link-disabled-icon-bullet-bg: #{$sidebar-nav-link-disabled-icon-bullet-bg};
  --#{$prefix}sidebar-nav-link-disabled-icon-bullet-border-color: #{$sidebar-nav-link-disabled-icon-bullet-border-color};
  --#{$prefix}sidebar-nav-link-hover-icon-bullet-bg: #{$sidebar-nav-link-hover-icon-bullet-bg};
  --#{$prefix}sidebar-nav-link-hover-icon-bullet-border-color: #{$sidebar-nav-link-hover-icon-bullet-border-color};

  --#{$prefix}sidebar-nav-group-bg: #{$sidebar-nav-group-bg};
  --#{$prefix}sidebar-nav-group-border-width: #{$sidebar-nav-group-border-width};
  --#{$prefix}sidebar-nav-group-border-radius: #{$sidebar-nav-group-border-radius};
  --#{$prefix}sidebar-nav-group-border-color: #{$sidebar-nav-group-border-color};
  --#{$prefix}sidebar-nav-group-items-padding-y: #{$sidebar-nav-group-items-padding-y};
  --#{$prefix}sidebar-nav-group-items-padding-x: #{$sidebar-nav-group-items-padding-x};
  --#{$prefix}sidebar-nav-group-indicator-color: #{$sidebar-nav-group-indicator-color};
  --#{$prefix}sidebar-nav-group-indicator-icon: #{escape-svg($sidebar-nav-group-indicator-icon)};
  --#{$prefix}sidebar-nav-group-indicator-hover-color: #{$sidebar-nav-group-indicator-hover-color};
  --#{$prefix}sidebar-nav-group-indicator-hover-icon: #{escape-svg($sidebar-nav-group-indicator-hover-icon)};
  --#{$prefix}sidebar-nav-group-toggle-show-color: #{$sidebar-nav-group-toggle-show-color};
  // scss-docs-end sidebar-nav-css-vars

  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: var(--#{$prefix}sidebar-nav-padding-y) var(--#{$prefix}sidebar-nav-padding-x);
  margin-bottom: 0;
  overflow-x: hidden;
  overflow-y: auto;
  list-style: none;

  .nav-item + .nav-item,
  .nav-item + .nav-group,
  .nav-group + .nav-item {
    margin-top: 1px;
  }

  .nav-title {
    padding: var(--#{$prefix}sidebar-nav-title-padding-y) var(--#{$prefix}sidebar-nav-title-padding-x);
    margin-top: var(--#{$prefix}sidebar-nav-title-margin-top);
    font-size: 80%;
    font-weight: 700;
    color: var(--#{$prefix}sidebar-nav-title-color);
    text-transform: uppercase;
    @include transition($sidebar-nav-title-transition);
  }

  .nav-link {
    display: flex;
    flex: 1;
    align-items: center;
    padding: var(--#{$prefix}sidebar-nav-link-padding-y) var(--#{$prefix}sidebar-nav-link-padding-x);
    color: var(--#{$prefix}sidebar-nav-link-color);
    text-decoration: none;
    white-space: nowrap;
    background: var(--#{$prefix}sidebar-nav-link-bg);
    border: var(--#{$prefix}sidebar-nav-link-border-width) solid var(--#{$prefix}sidebar-nav-link-border-color);
    @include border-radius(var(--#{$prefix}sidebar-nav-link-border-radius));
    @include transition($sidebar-nav-link-transition);

    &.active {
      color: var(--#{$prefix}sidebar-nav-link-active-color);
      background: var(--#{$prefix}sidebar-nav-link-active-bg);

      .nav-icon {
        color: var(--#{$prefix}sidebar-nav-link-active-icon-color);
      }

      .nav-icon-bullet {
        background: var(--#{$prefix}sidebar-link-active-icon-bullet-bg);
        border-color: var(--#{$prefix}sidebar-link-active-icon-bullet-border-color);
      }
    }

    &.disabled {
      color: var(--#{$prefix}sidebar-nav-link-disabled-color);
      pointer-events: none;
      cursor: not-allowed;
      background: transparent;

      .nav-icon {
        color: var(--#{$prefix}sidebar-nav-link-disabled-icon-color);
      }

      .nav-icon-bullet {
        background: var(--#{$prefix}sidebar-link-disabled-icon-bullet-bg);
        border-color: var(--#{$prefix}sidebar-link-disabled-icon-bullet-border-color);
      }

      &:hover {
        color: var(--#{$prefix}sidebar-nav-link-disabled-color);

        .nav-icon {
          color: var(--#{$prefix}sidebar-nav-link-disabled-icon-color);
        }

        .nav-icon-bullet {
          background: var(--#{$prefix}sidebar-link-disabled-icon-bullet-bg);
          border-color: var(--#{$prefix}sidebar-link-disabled-icon-bullet-border-color);
        }

        &.nav-dropdown-toggle::after {
          background-color: var(--#{$prefix}sidebar-nav-group-indicator-hover-color);
          mask-image: var(--#{$prefix}sidebar-nav-group-indicator-hover-icon);
        }
      }
    }

    @media (hover: hover), (-ms-high-contrast: none) {
      &:hover {
        color: var(--#{$prefix}sidebar-nav-link-hover-color);
        text-decoration: none;
        background: var(--#{$prefix}sidebar-nav-link-hover-bg);

        .nav-icon {
          color: var(--#{$prefix}sidebar-nav-link-hover-icon-color);
        }

        .nav-icon-bullet {
          background: var(--#{$prefix}sidebar-link-hover-icon-bullet-bg);
          border-color: var(--#{$prefix}sidebar-link-hover-icon-bullet-border-color);
        }

        &.nav-group-toggle::after {
          background-color: var(--#{$prefix}sidebar-nav-group-indicator-hover-color);
          mask-image: var(--#{$prefix}sidebar-nav-group-indicator-hover-icon);
        }
      }
    }
  }

  .nav-icon {
    display: flex;
    flex: 0 0 var(--#{$prefix}sidebar-nav-link-icon-width);
    align-items: center;
    justify-content: center;
    height: var(--#{$prefix}sidebar-nav-link-icon-height);
    @include ltr-rtl("margin-right", var(--#{$prefix}sidebar-nav-link-icon-margin));
    font-size: var(--#{$prefix}sidebar-nav-link-icon-font-size);
    color: var(--#{$prefix}sidebar-nav-link-icon-color);
    text-align: center;
    pointer-events: none;
    fill: currentcolor;
    @include transition(inherit);
  }

  .nav-icon-bullet {
    display: inline-block;
    width: var(--#{$prefix}sidebar-nav-link-icon-bullet-size);
    height: var(--#{$prefix}sidebar-nav-link-icon-bullet-size);
    background: var(--#{$prefix}sidebar-nav-link-icon-bullet-bg);
    border: var(--#{$prefix}sidebar-nav-link-icon-bullet-border-width) solid var(--#{$prefix}sidebar-nav-link-icon-bullet-border-color);
    border-radius: var(--#{$prefix}sidebar-nav-link-icon-bullet-border-radius); // stylelint-disable-line property-disallowed-list
  }

  // stylelint-disable-next-line selector-no-qualifying-type
  svg.nav-icon {
    overflow: hidden; // fix chrome 105+ width issue
  }

  .nav-group {
    position: relative;
    border: var(--#{$prefix}sidebar-nav-group-border-width) solid var(--#{$prefix}sidebar-nav-group-border-color);
    @include border-radius(var(--#{$prefix}sidebar-nav-group-border-radius));
    @include transition($sidebar-nav-group-transition);

    .nav-group-items {
      padding: var(--#{$prefix}sidebar-nav-group-items-padding-y) var(--#{$prefix}sidebar-nav-group-items-padding-x);
      overflow: hidden;
      @include transition($sidebar-nav-group-items-transition);
    }

    &:not(.show) .nav-group-items {
      display: none;
    }

    &.show {
      background: var(--#{$prefix}sidebar-nav-group-bg);

      .nav-group-toggle {
        color: var(--#{$prefix}sidebar-nav-group-toggle-show-color);
      }

      > .nav-group-toggle::after {
        transform: rotate(180deg);
      }

      + .show {
        margin-top: 1px;
      }
    }
  }

  .nav-group-toggle {
    cursor: pointer;

    &::after {
      display: block;
      flex: 0 12px;
      height: 12px;
      @include ltr-rtl("margin-left", auto);
      content: "";
      background-color: var(--#{$prefix}sidebar-nav-group-indicator-color);
      mask-image: var(--#{$prefix}sidebar-nav-group-indicator-icon);
      @include transition($sidebar-nav-group-indicator-transition);
    }
  }

  .nav-group-items {
    padding: 0;
    list-style: none;

    .nav-link {
      @include ltr-rtl("padding-left", calc(var(--#{$prefix}sidebar-nav-link-padding-x) + var(--#{$prefix}sidebar-nav-link-icon-width) + var(--#{$prefix}sidebar-nav-link-icon-margin)));
    }

    .nav-icon {
      @include ltr-rtl("margin-left", calc((var(--#{$prefix}sidebar-nav-link-icon-width) + var(--#{$prefix}sidebar-nav-link-icon-margin)) * -1));
    }
  }

  &.compact,
  .compact {
    .nav-link {
      --#{$prefix}sidebar-nav-link-padding-y: #{$sidebar-compact-nav-link-padding-y};
    }
  }
}
