// All-caps `RGBA()` function used because of this Sass bug: https://github.com/sass/node-sass/issues/2251
@each $color, $value in $theme-colors {
  .text-bg-#{$color} {
    color: color-contrast($value) if($enable-important-utilities, !important, null);
    background-color: RGBA(var(--#{$prefix}#{$color}-rgb), var(--#{$prefix}bg-opacity, 1)) if($enable-important-utilities, !important, null);
  }
}

@each $color, $value in $theme-gradients {
  .text-bg-#{$color}-gradient {
    color: color-contrast(map-get($value, "start")) if($enable-important-utilities, !important, null);
    background-color: var(--#{$prefix}#{$color});
    background-image: linear-gradient(45deg, var(--#{$prefix}#{$color}-start) 0%, var(--#{$prefix}#{$color}-stop) 100%);
  }
}

@if $enable-dark-mode {
  @include color-mode(dark) {
    @each $color, $value in $theme-colors-dark {
      $color-rgb: to-rgb($value);
      .text-bg-#{$color} {
        color: color-contrast($value) if($enable-important-utilities, !important, null);
        background-color: RGBA($color-rgb, var(--#{$prefix}bg-opacity, 1)) if($enable-important-utilities, !important, null);
      }
    }

    @each $color, $value in $theme-gradients-dark {
      .text-bg-#{$color}-gradient {
        color: color-contrast(map-get($value, "start")) if($enable-important-utilities, !important, null);
      }
    }
  }
}
